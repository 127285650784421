/* eslint-disable camelcase */
/* eslint-disable semi */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import env from '../../config/env';
import { SetAuthTokenRequest, checkTokenExpired } from '../../config/interceptor';

const api = axios.create({
  baseURL: env.financialApi + env.apiPrefixV1
});
api.interceptors.request.use(SetAuthTokenRequest, null);
api.interceptors.response.use(response => response, checkTokenExpired);

export const getCashStatus2 = (office_id) => api.post(`cashiers/status?office_id=${office_id}`, {
  balance: 0,
  type: 'credit'
});
