import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { Button, makeStyles, Link, CircularProgress } from '@material-ui/core';
import TextFieldLogin from '../../../../components/FormFields/TextFieldLogin';
import TextFieldPassword from '../../../../components/FormFields/TextFieldPassword';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '80%',
    [theme.breakpoints.only('xs')]: {
      width: '95%'
    }
  },
  container: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  buttonContainer2: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2)
  },
  rememberMe: {
    flex: 1,
  },
  linkText: {
    fontFamily: 'Open Sans',
  }
}));

const LoginForm = ({ initialValues, onSubmit }) => {
  const classes = useStyles();

  const validateUsername = (value) => {
    let error;
    if (!value) {
      error = 'Email atau NIK tidak boleh kosong !';
    }
    return error;
  };

  const validatePassword = (value) => {
    let error;

    if (!value) {
      error = 'Kata Sandi tidak boleh kosong !';
    }

    return error;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (value) => {
        await onSubmit(value);
      }}
      component={({ submitForm, isSubmitting, errors, touched }) => (
        <Form autoComplete="off" className={classes.container}>
          <div className={classes.form}>
            <Field
              name="username"
              placeholder="Email atau NIK"
              variant="outlined"
              margin="normal"
              autoComplete="off"
              validate={validateUsername}
              fullWidth
              component={TextFieldLogin}
              helperText={touched.username && errors.username}
            />
            <Field
              name="password"
              placeholder="Kata Sandi"
              variant="outlined"
              margin="normal"
              autoComplete="off"
              validate={validatePassword}
              fullWidth
              component={TextFieldPassword}
              helperText={touched.password && errors.password}
            />
            <div className={classes.buttonContainer}>
              <div className={classes.buttonContainer2}>
                <div className={classes.rememberMe} />
                <Link
                  className={classes.linkText}
                  href="/lupa-kata-sandi"
                  color="primary"
                >
                  Lupa kata sandi ?
                </Link>
              </div>
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
                variant="contained"
                fullWidth
                style={{ color: 'white', marginTop: 12 }}
              >
                {
                  isSubmitting ? <CircularProgress color="secondary" size={24} /> : 'Masuk'
                }
              </Button>
              <input style={{ display: 'none' }} type="submit" />
            </div>
          </div>
        </Form>
      )}
    />
  );
};

LoginForm.propTypes = ({
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
});

export default LoginForm;
