import React, { Fragment, memo } from 'react';
import { ActionCableConsumer } from 'react-actioncable-provider';
import parse from 'html-react-parser';
import { useSnackbar } from 'notistack';
import useNotification from './useNotification';

function NotificationGateway(props) {
  const { children } = props;
  const { hydrated, addOne } = useNotification();
  const { enqueueSnackbar } = useSnackbar();
  const channel = {
    channel: 'SiscabChannel',
    token: localStorage.getItem('token'),
  };

  const handleReceived = (item) => {
    if (item.id) {
      const message = item?.body ? parse(`INFO: <span>${item?.body}</span>`) : 'Notifikasi Baru';
      enqueueSnackbar(message, { variant: 'info' });
      addOne(item);
    }
  };

  return hydrated && (
    <Fragment>
      <ActionCableConsumer channel={channel} onReceived={handleReceived} />
      {children}
    </Fragment>
  );
}

export default memo(NotificationGateway);
