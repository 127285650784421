import { makeStyles } from '@material-ui/core';

export const drawerWidth = 300;
export const appbarHeight = 80;

const useStyles = makeStyles(() => ({
  notification: {
    width: 380,
    height: 'auto',
    maxHeight: 340,
    borderRadius: 5,
    marginLeft: -150,
    border: 'solid 0.5px #cccccc',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiList-root': {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      width: '100%',
      height: '100%',
    },
    '& .Notification-listContainer': {
      flex: 1,
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: 6,
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 0,
        backgroundColor: 'RGBA(134, 44, 58)',
      },
    },
    '& .Notification-header': {
      padding: '8px 16px',
      fontSize: '1.2rem',
      boxShadow: 'rgb(0 0 0 / 15%) 0px 1px 2px 0px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .Notification-unread': {
      backgroundColor: '#FFEAED',
    },
    '& .Notification-title': {
      color: '#676767',
      fontSize: '1rem',
      fontWeight: 600
    },
    '& .MuiListItemText-multiline': {
      marginTop: 0,
      marginBottom: 0,
    },
    '& .Notification-body': {
      color: '#b1b1b1',
      fontSize: '0.875rem',
    },
    '& .Notification-date': {
      color: '#676767',
      fontSize: '0.875rem',
    },
    '& .Notification-footer': {
      boxShadow: 'rgb(0 0 0 / 15%) 0px -1px 2px 0px',
      display: 'flex',
      justifyContent: 'stretch',
      '& .MuiButton-root': {
        borderRadius: 0,
        flex: 1,
        padding: '8px 16px',
        '&:first-of-type .MuiButton-label': {
          justifyContent: 'flex-start',
        },
        '&:last-of-type .MuiButton-label': {
          justifyContent: 'flex-end',
        },
      },
      '& .MuiButton-label': {
        fontSize: '0.7rem',
      },
      '& .MuiButton-endIcon': {
        margin: 0,
        marginLeft: '5px',
        '& .MuiSvgIcon-root': {
          fontSize: '0.7rem',
        },
      },
    },
  },
}));

export default useStyles;
