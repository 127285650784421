/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Toolbar,
  Typography,
  IconButton,
  Avatar,
  MenuItem,
  Menu,
  Icon,
  withStyles,
  ListItemIcon,
  ListItemText,
  Button,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import MenuIcon from '@material-ui/icons/Menu';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Skeleton from '@material-ui/lab/Skeleton';
import useStyles from './appbar-jss';
import logo from '../../assets/icon/logo.png';
import Notification from '../Notification';
import CustomBadge from '../Notification/CustomBadge';
import { setPicture } from '../../redux';
import Func from '../../functions';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    minWidth: 340,
    borderRadius: 10,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const onClickSignOut = () => {
  localStorage.clear();
  window.location = '/login';
};

const Header = ({ mainMenu, open, setMobileMenuOpen, mobileMenuOpen }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null); // buat dialog notif
  const [menuOpen, setMenuOpen] = useState(false);
  const user = localStorage.getItem('name') ? localStorage.getItem('name') : '';
  const photo = useSelector(({ userAccount }) => userAccount.picture);

  useEffect(() => {
    dispatch(setPicture(localStorage.getItem('photo')));
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickNotif = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleCloseNotif = () => {
    setMenuOpen(!menuOpen);
    setAnchorEl2(null);
  };

  const handleReceived = (msg) => {
    enqueueSnackbar(`Info : ${msg?.body || 'notifikasi baru'}`, {
      variant: 'info',
    });
  };

  const menuDekstop = () => (
    <StyledMenu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      className={classes.menuDekstop}
    >
      <StyledMenuItem
        onClick={() => {
          handleClose();
          history.replace('/app/profile');
        }}
        className={classes.menuItem}
      >
        <ListItemIcon>
          <Avatar>
            <Icon>person</Icon>
          </Avatar>
        </ListItemIcon>
        <ListItemText primary="Profile" secondary="Lihat dan Ubah Profil" />
      </StyledMenuItem>
      <StyledMenuItem
        onClick={() => {
          handleClose();
          history.replace('/app/change-password');
        }}
        className={classes.menuItem}
      >
        <ListItemIcon>
          <Avatar>
            <Icon>settings_icon</Icon>
          </Avatar>
        </ListItemIcon>
        <ListItemText primary="Pengaturan" secondary="Ubah Kata Sandi" />
      </StyledMenuItem>

      <div className={classes.signOutButton}>
        <Button color="primary" variant="outlined" onClick={onClickSignOut}>
          Sign Out
        </Button>
      </div>
    </StyledMenu>
  );

  return (
    <AppBar
      position="fixed"
      color="#FFFFFF"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar className={classes.toolBar}>
        <div className={classes.menuIcon}>
          <IconButton
            className={classes.menuIcon}
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <div
          className={clsx(classes.toolbarTitle, {
            [classes.toolbarTitleShift]: open,
          })}
        >
          <Typography variant="h6" noWrap style={{ fontWeight: 'bold' }}>
            {mainMenu}
          </Typography>
        </div>
        <div className={classes.mobileTitle}>
          <img src={logo} alt="logo" height="60" />
        </div>
        <div className={classes.statusBar}>
          <Typography
            className={(classes.typography, classes.typographyDashboard)}
          >
            <Typography style={{ fontSize: 12, color: '#000000' }}>
              Saldo Pettycash
            </Typography>
            {Func.currencyFormatter(localStorage.getItem('petty_cash')) || <Skeleton variant="text" />}
          </Typography>
          <Typography
            className={(classes.typography, classes.typographyDashboard)}
          >
            <Typography style={{ fontSize: 12, color: '#000000' }}>
              Saldo Kas Operasional
            </Typography>
            {Func.currencyFormatter(localStorage.getItem('balance')) || <Skeleton variant="text" />}
          </Typography>
          <Typography
            className={(classes.typography, classes.typographyDashboard)}
          >
            <Typography style={{ fontSize: 12, color: '#000000' }}>
              Status Kas
            </Typography>
            {localStorage.getItem('cashStatus') || <Skeleton variant="text" />}
          </Typography>
        </div>
        <div className={classes.notificationIcon}>
          <CustomBadge
            handleClickNotif={handleClickNotif}
            classes={classes}
          />
        </div>
        <div>
          <Typography className={classes.typography}>
            Hi, {user} .
            <Typography style={{ fontSize: 12 }}>
              {localStorage.getItem('role_name')}
            </Typography>
          </Typography>
        </div>
        <IconButton onClick={handleClick} className={classes.avatarIcon}>
          <Avatar alt="Avatar" src={photo} className={classes.avatar} />
        </IconButton>
        {menuDekstop()}
        <Notification
          anchorEl2={anchorEl2}
          handleClose={handleCloseNotif}
        />
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  mainMenu: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  mobileMenuOpen: PropTypes.bool.isRequired,
  setMobileMenuOpen: PropTypes.func.isRequired,
};

export default Header;
