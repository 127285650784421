/* eslint-disable react/destructuring-assignment */
/* eslint-disable semi */
import React from 'react';
import {
  Home,
  Person,
  Receipt,
  MenuBook,
  Assignment,
  LocalAtm,
} from '@material-ui/icons';
import dayjs from 'dayjs';
import Func from '../functions/index';
import Icon from '../components/icon';

const dateObj = new Date();
const now = dayjs(dateObj).format('YYYY-MM-DD')

const SetCustomIcon = (path, desc) => (
  <img
    src={path.path}
    width="20"
    height="20"
    alt={desc}
  />
)

const menu = [
  {
    title: 'Dashboard',
    link: '/home',
    active: Func.checkPermission('home'),
    key: 'home',
    icon: <Home />,
    breadcrumb: {
      '/home': 'Home',
    },
    subMenu: [
      {
        title: 'Dashboard',
        key: 'home#dashboard',
        active: Func.checkPermission('home#dashboard'),
        link: '/home/dashboard',
        icon: 'lens',
        breadcrumb: {
          '/home/dashboard': 'Dashboard',
        },
      },
    ],
  },
  {
    title: 'Data Master',
    link: '/customer',
    active: Func.checkPermission('customer'),
    key: 'customer',
    icon: <Person />,
    breadcrumb: {
      '/customer': 'Data Master',
    },
    subMenu: [
      {
        title: 'Perorangan',
        active: Func.checkPermission('customer#individual'),
        key: 'customer#individual',
        link: '/customer/individual',
        icon: 'lens',
        breadcrumb: {
          '/customer/individual': 'Perorangan',
        },
      },
      {
        title: 'HPS Elektronik',
        active: Func.checkPermission('customer#individual'),
        key: 'customer#elektronik',
        link: '/customer/elektronik',
        icon: 'lens',
        breadcrumb: {
          '/customer/elektronik': 'HPS Elektronik',
        },
      },
      {
        title: 'Sales Agent',
        active: Func.checkPermission('customer#individual'),
        key: 'customer#sales_agent',
        link: '/customer/sales_agent',
        icon: 'lens',
        breadcrumb: {
          '/customer/sales_agent': 'Sales Agent',
        },
      },
    ],
  },
  {
    title: 'Manajemen Transaksi',
    link: '/transaction',
    active: Func.checkPermission('transaction'),
    key: 'transaction',
    icon: <Receipt />,
    breadcrumb: {
      '/transaction': 'Transaksi',
    },
    subMenu: [
      {
        title: 'Perorangan',
        link: '/transaction/individual',
        active: Func.checkPermission('transaction#individual'),
        key: 'individual',
        icon: 'lens',
        breadcrumb: {
          '/transaction/individual': 'Perorangan',
        },
      },
      {
        title: 'Barang Jaminan',
        link: '/transaction/stock',
        active: Func.checkPermission('transaction#stock'),
        key: 'stock',
        icon: 'lens',
        breadcrumb: {
          '/transaction/stock': 'Barang Jaminan',
        },
      },
      {
        title: 'Approval Deviasi',
        link: '/transaction/deviation',
        active: Func.checkPermission('transaction#deviation'),
        key: 'deviation',
        icon: 'lens',
        breadcrumb: {
          '/transaction/deviation': 'Approval Deviasi',
        },
      },
      {
        title: 'One Obligor',
        link: '/transaction/oneobligor',
        active: Func.checkPermission('transaction#oneobligor'),
        key: 'oneobligor',
        icon: 'lens',
        breadcrumb: {
          '/transaction/oneobligor': 'One Obligor',
        },
      },
      {
        title: 'Non Transaksional',
        link: '/transaction/non-transactional',
        active: Func.checkPermission('transaction#non-transactional'),
        key: 'non-transactional',
        icon: 'lens',
        breadcrumb: {
          '/transaction/non-transactional': 'Non Transaksional',
        },
      },
      {
        title: 'Histori Perpanjangan',
        link: '/transaction/history-prolongation',
        active: Func.checkPermission('transaction#history-prolongation'),
        key: 'history-prolongation',
        icon: 'lens',
        breadcrumb: {
          '/transaction/history-prolongation': 'Histori Perpanjangan',
        },
      },
    ],
  },
  {
    title: 'Jurnal',
    link: '/journal',
    active: Func.checkPermission('journal'),
    key: 'journal',
    icon: <MenuBook />,
    breadcrumb: {
      '/journal': 'Jurnal',
    },
    subMenu: [
      {
        key: 'all',
        title: 'Semua',
        active: Func.checkPermission('journal#all'),
        link: '/journal/all',
        icon: 'lens',
        breadcrumb: {
          '/journal/all': 'Semua',
        },
      },
      {
        key: 'accrual',
        title: 'Pendapatan YMHD',
        link: '/journal/accrual',
        active: Func.checkPermission('journal#accrual'),
        icon: 'lens',
        breadcrumb: {
          '/journal/accrual': 'Pendapatan YMHD',
        },
      },
      {
        key: 'add',
        title: 'Tambah Jurnal',
        link: '/journal/add',
        active: Func.checkPermission('journal#add'),
        icon: 'lens',
        breadcrumb: {
          '/journal/add': 'Tambah Jurnal',
        },
      },
    ],
  },
  {
    title: 'Laporan Transaksi',
    link: '/report',
    active: Func.checkPermission('report'),
    key: 'report',
    icon: <Assignment />,
    breadcrumb: {
      '/report': 'Laporan Transaksi',
    },
    subMenu: [
      {
        key: 'all',
        title: 'Semua',
        active: Func.checkPermission('report#all'),
        link: '/report/all',
        icon: 'lens',
        breadcrumb: {
          '/report/all': 'Semua',
        },
      },
    ],
  },
  {
    title: 'Manajemen Kas',
    link: '/cashflow',
    active: Func.checkPermission('cashflow'),
    key: 'cashflow',
    icon: <SetCustomIcon path={Icon.cashflow} desc="cashflow" />,
    breadcrumb: {
      '/cashflow': 'Manajemen Kas',
    },
    subMenu: [
      {
        key: 'cashflow/all',
        title: 'Saldo Kas',
        active: Func.checkPermission('cashflow#all'),
        link: '/cashflow/all?page=1&per_page=10',
        icon: 'lens',
        breadcrumb: {
          '/cashflow/all': 'Saldo Kas',
        },
      },
      {
        key: 'cashflow/cash-journal',
        title: 'Buku Kas',
        active: Func.checkPermission('cashflow#cash-journal'),
        link: `/cashflow/cash-journal?page=1&per_page=10&q[created_at_gteq]=${now}&q[created_at_lteq]=${now}`,
        icon: 'lens',
        breadcrumb: {
          '/cashflow/cash-journal': 'Buku Kas',
        },
      },
      {
        key: 'cashflow/petty-cash',
        title: 'Petty Cash',
        active: Func.checkPermission('cashflow#cash-journal'),
        // active: Func.checkPermission('cashflow#petty-cash'),
        link: `/cashflow/petty-cash?page=1&per_page=10&q[created_at_gteq]=${now}&q[created_at_lteq]=${now}`,
        icon: 'lens',
        breadcrumb: {
          '/cashflow/petty-cash': 'Petty Cash'
        },
      },
      {
        key: 'cashflow/combined-cash',
        title: 'Gabungan Buku Kas',
        active: Func.checkPermission('cashflow#cash-journal'),
        // active: Func.checkPermission('cashflow#petty-cash'),
        link: `/cashflow/combined-cash?page=1&per_page=10&q[created_at_gteq]=${now}&q[created_at_lteq]=${now}`,
        icon: 'lens',
        breadcrumb: {
          '/cashflow/combined-cash': 'Gabungan Buku Kas'
        },
      },
    ]
  },
  {
    title: 'Modal Kerja',
    link: '/workcap',
    active: Func.checkPermission('workcap'),
    key: 'workcap',
    icon: <LocalAtm />,
    breadcrumb: {
      '/workcap': 'Modal Kerja',
    },
    subMenu: [
      {
        title: 'History & Draft',
        key: 'workcap#moker',
        active: Func.checkPermission('workcap#moker'),
        link: '/workcap/moker',
        icon: 'lens',
        breadcrumb: {
          '/workcap/moker': 'History & Draft',
        },
      },
      {
        title: 'Approval',
        key: 'workcap#approval',
        active: Func.checkPermission('workcap#approval'),
        link: '/workcap/approval',
        icon: 'lens',
        breadcrumb: {
          '/workcap/approval': 'Approval',
        },
      },
      {
        title: 'Mutasi Modal Kerja',
        key: 'workcap#workcap-mutation',
        active: Func.checkPermission('workcap#workcap-mutation'),
        link: '/workcap/workcap-mutation',
        icon: 'lens',
        breadcrumb: {
          '/workcap/workcap-mutation': 'Mutasi Modal Kerja',
        },
      },
    ],
  },
  {
    title: 'Pengaturan Profil',
    link: '/profile',
    active: false,
    key: 'profile',
    icon: <LocalAtm />,
    breadcrumb: {
      '/profile': 'Pengaturan Profil',
    },
  },
  {
    title: 'Ubah Kata Sandi',
    link: '/change-password',
    active: false,
    key: 'change-password',
    icon: <LocalAtm />,
    breadcrumb: {
      '/change-password': 'Ubah Kata Sandi',
    },
  },
  {
    title: 'Notifikasi',
    link: '/notification',
    active: false,
    key: 'notification',
    icon: <LocalAtm />,
    breadcrumb: {
      '/notification': 'Notifikasi',
    },
  },
];

export default menu;
