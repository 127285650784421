/* eslint-disable semi */
/* eslint-disable eqeqeq */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-useless-escape */
/* eslint-disable no-redeclare */
/* eslint-disable block-scoped-var */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable array-callback-return */
/* eslint-disable no-bitwise */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
import React from 'react';
import Swal2 from 'sweetalert2';
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert';
import env from '../config/env';

const role = [
  {
    module_name: 'dashboard',
    module_type: 'main',
    module_prefix: 'home',
  },
  {
    module_name: 'dashboard',
    module_type: 'sub',
    module_prefix: 'home#dashboard',
  },
  {
    module_name: 'accrual',
    module_type: 'sub',
    module_prefix: 'accrual#cash-journal#export',
  },
];
if (localStorage.getItem('user_role') != undefined) {
  JSON.parse(localStorage.getItem('user_role')).map((value) => {
    role.push(value);
  });
}

const Func = {
  Clear_Token() {
    localStorage.clear();
    return true;
  },
  Refresh_Token() {
    let flag = true;
    fetch(process.env.REACT_APP_URL_TOKEN, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        grant_type: 'refresh_token',
        refresh_token: localStorage.getItem('refresh_token'),
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.message === 'login berhasil') {
          localStorage.setItem('token', json.access_token);
          localStorage.setItem('refresh_token', json.refresh_token);
        } else {
          flag = false;
        }
      })
      .catch((error) => {})
      .finally(() => {});
    return flag;
  },
  toLogin(flag) {
    if (flag) {
      return <Redirect to='/login' />;
    }
  },
  getData(url, per_page, page, search = '') {
    return new Promise((resolve, reject) => {
      fetch(
        env.masterApi +
          env.apiPrefixV1 +
          '/' +
          url +
          '?per_page=' +
          per_page +
          '&page=' +
          page +
          (search === '' ? '' : search),
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve({ json });
        })
        .catch((error) => {})
        .finally(() => {});
    });
  },
  getDataAuth(url, per_page, page, search = '') {
    return new Promise((resolve, reject) => {
      fetch(
        env.financialApi +
          env.apiPrefixV1 +
          '/' +
          url +
          '?per_page=' +
          per_page +
          '&page=' +
          page +
          (search === '' ? '' : search),
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve({ json });
        })
        .catch((error) => {})
        .finally(() => {});
    });
  },
  serialize(obj, prefix) {
    const str = [];
    let k;
    let v;
    for (const p in obj) {
      if (!obj.hasOwnProperty(p)) {
        continue;
      } // skip things from the prototype
      if (~p.indexOf('[')) {
        k = prefix
          ? prefix +
            '[' +
            p.substring(0, p.indexOf('[')) +
            ']' +
            p.substring(p.indexOf('['))
          : p;
      } else {
        k = prefix ? prefix + '[' + p + ']' : p;
      }
      v = obj[p];
      str.push(
        typeof v === 'object'
          ? this.serialize(v, k)
          : k + '=' + encodeURIComponent(v)
      );
    }
    return str.join('&');
  },
  getDataTransaction(url, per_page, page, search = '', q) {
    if (q != undefined) {
      const a = {
        customer_name_cont:
          q.customer_name_cont === undefined ? '' : q.customer_name_cont,
        insurance_item_id_eq:
          q.insurance_item_id_eq === undefined ? '' : q.insurance_item_id_eq,
      };
      var qr = '&' + this.serialize(a, 'q');
    } else {
      var qr = '';
    }
    return new Promise((resolve, reject) => {
      fetch(
        env.managementApi +
          env.apiPrefixV1 +
          `/${url}` +
          '&per_page=' +
          per_page +
          '&page=' +
          page +
          (search === null || search === '' ? '' : '&q[sge_end]=' + search) +
          qr,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve({ json });
        })
        .catch((error) => {})
        .finally(() => {});
    });
  },
  getDataTransactionV2(url, per_page, page, search = '') {
    return new Promise((resolve, reject) => {
      fetch(
        env.managementApi +
          env.apiPrefixV1 +
          `/${url}` +
          '&token=' +
          localStorage.getItem('token') +
          '&per_page=' +
          per_page +
          '&page=' +
          page +
          (search === null || search === '' ? '' : '&search=' + search),
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve({ json });
        })
        .catch((error) => {})
        .finally(() => {});
    });
  },
  getDataHPSElektronik(id_region = '') {
    return new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_BASE_URL_MANAJEMENT}/${env.apiPrefixV1}/electonic_hps?q[region_id_eq]=60b48adbe64d1e7cb04bfc42`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve({ json });
        })
        .catch((error) => {})
        .finally(() => {});
    });
  },
  getDataTransaction2(url, per_page, page, q) {
    if (q != undefined) {
      const a = {
        cif_number_cont:
          q.cif_number_cont === undefined ? '' : q.cif_number_cont,
        identity_number_cont:
          q.identity_number_cont === undefined ? '' : q.identity_number_cont,
        name_cont: q.name_cont === undefined ? '' : q.name_cont,
      };
      const b = {
        order_by: q.order_by === undefined ? '' : q.order_by,
        order: q.order === undefined ? '' : q.order,
      };
      var qr = '&' + this.serialize(a, 'q') + '&' + this.serialize(b, '');
    } else {
      var qr = '';
    }
    return new Promise((resolve, reject) => {
      fetch(
        env.managementApi +
          env.apiPrefixV1 +
          `/${url}` +
          '?per_page=' +
          per_page +
          '&page=' +
          page +
          qr,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve({ json });
        })
        .catch((error) => {})
        .finally(() => {});
    });
  },
  getDataTransaction3(url, per_page, page, serialize) {
    return new Promise((resolve, reject) => {
      fetch(
        env.managementApi +
          env.apiPrefixV1 +
          `/${url}` +
          '?per_page=' +
          per_page +
          '&page=' +
          page +
          serialize,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve({ json });
        })
        .catch((error) => {})
        .finally(() => {});
    });
  },

  getDataFinancial(url, per_page, page, search = '') {
    return new Promise((resolve, reject) => {
      fetch(
        env.financialApi +
          env.apiPrefixV1 +
          '/' +
          url +
          '?per_page=' +
          per_page +
          '&page=' +
          page +
          (search === '' ? '' : '&search=' + search),
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve({ json });
        })
        .catch((error) => {})
        .finally(() => {});
    });
  },
  getDataFinancialV2(url, per_page, page, search = '') {
    return new Promise((resolve, reject) => {
      fetch(
        env.financialApi +
          env.apiPrefixV1 +
          `/${url}` +
          '&token=' +
          localStorage.getItem('token') +
          '&per_page=' +
          per_page +
          '&page=' +
          page +
          (search === null || search === '' ? '' : '&search=' + search),
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve({ json });
        })
        .catch((error) => {})
        .finally(() => {});
    });
  },
  getDataJournal(url, per_page, page, search = '') {
    return new Promise((resolve, reject) => {
      fetch(
        env.financialApi +
          env.apiPrefixV1 +
          '/' +
          url +
          '&per_page=' +
          per_page +
          '&page=' +
          page +
          (search === '' ? '' : '&search=' + search),
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve({ json });
        })
        .catch((error) => {})
        .finally(() => {});
    });
  },
  getDataJournalV2(url, per_page, page, search = '') {
    return new Promise((resolve, reject) => {
      fetch(
        env.financialApi +
          env.apiPrefixV1 +
          `/${url}` +
          '&token=' +
          localStorage.getItem('token') +
          '&per_page=' +
          per_page +
          '&page=' +
          page +
          (search === null || search === '' ? '' : '&search=' + search),
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve({ json });
        })
        .catch((error) => {})
        .finally(() => {});
    });
  },
  deleteItem(key, key2) {
    return new Promise((resolve, reject) => {
      fetch(env.masterApi + env.apiPrefixV1 + '/' + key + '/' + key2, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
        .then((response) => response.json())
        .then((json) => {
          resolve({ json });
        })
        .catch((error) => {})
        .finally(() => {});
    });
  },
  Alert(ttl, msg, icon) {
    Swal2.fire({
      title: ttl,
      text: msg,
      imageUrl: icon,
      imageWidth: 100,
      imageHeight: 100,
      borderRadius: 20,
      confirmButtonColor: '#C4A643',
      imageAlt: 'Custom image',
    });
  },
  AlertForm(ttl, msg, type) {
    Swal2.fire({
      title: ttl,
      text: msg,
      icon: type,
      confirmButtonText: 'Oke',
    }).then((result) => {
      window.location.reload();
    });
  },
  AlertErrorVer2(msg, callback) {
    swal({
      title: 'Kesalahan',
      text: msg,
      icon: 'error',
      target: document.getElementById('form-modal'),
    })
    .then(callback);
  },
  AlertError(msg) {
    swal({
      title: 'Kesalahan',
      text: msg,
      icon: 'error',
    });
  },
  Validator(value, validate) {
    const error = [];
    let flag = true;
    validate.map((val) => {
      val.type.split('|').map((type) => {
        const type2 = type.split(':');
        if (type2[0] === 'required') {
          if (
            value[val.name] === null ||
            value[val.name] === '' ||
            value[val.name] === '-' ||
            value[val.name] === undefined
          ) {
            error[val.name] = 'Tidak boleh kosong';
            flag = false;
          }
        }
        if (type2[0] === 'required_0') {
          if (
            parseInt(value[val.name]) < 1 ||
            value[val.name] === null ||
            value[val.name] === '' ||
            value[val.name] === '-' ||
            value[val.name] === undefined
          ) {
            error[val.name] = 'Tidak boleh kosong';
            flag = false;
          }
        }
        if (type2[0] === 'requiredV2') {
          if (
            value[val.name] === null ||
            value[val.name] === '' ||
            value[val.name] === '-' ||
            value[val.name] === undefined
          ) {
            error[val.name] =
              this.capitalizeFirstLetter(val.name) + ' tidak boleh kosong !';
            flag = false;
          }
        }
        if (type2[0] === 'mail') {
          if (value[val.name] === '') {
            error[val.name] = 'Email tidak boleh kosong!';
            flag = false;
          } else if (
            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
              value[val.name]
            )
          ) {
            error[val.name] = 'Format email tidak sesuai';
            flag = false;
          }
        }
        if (type2[0] === 'same') {
          if (value[val.name] != value[type2[1]]) {
            error[val.name] = 'Konfirmasi kata sandi baru tidak sama';
            flag = false;
          }
        }
        if (type2[0] === 'min') {
          if (value[val.name] != undefined) {
            if (value[val.name].length < parseInt(type2[1])) {
              error[val.name] =
                'inputan minimal ' + parseInt(type2[1]) + ' karakter';
              flag = false;
            }
          }
        }
        if (type2[0] === 'minInt') {
          if (value[val.name] != undefined) {
            if (parseInt(value[val.name]) > parseInt(type2[1])) {
              error[val.name] = 'inputan minimal ' + parseInt(type2[1]);
              flag = false;
            }
          }
        }
        if (type2[0] === 'maxInt') {
          if (value[val.name] != undefined) {
            if (parseInt(value[val.name]) >= parseInt(type2[1])) {
              error[val.name] = 'inputan maximal ' + parseInt(type2[1]);
              flag = false;
            }
          }
        }
        if (type2[0] === 'onlyLetters') {
          if (value[val.name] != undefined) {
            const rgx = new RegExp('^[A-Za-z ]*$');
            if (!rgx.test(value[val.name])) {
              error[val.name] = 'Tidak boleh ada angka atau simbol!';
              flag = false;
            }
          }
        }
        if (type2[0] === 'onlyNumber') {
          if (value[val.name] != undefined) {
            const rgx = new RegExp('^[0-9,.]*$');
            if (!rgx.test(value[val.name])) {
              error[val.name] = 'Tidak boleh ada huruf atau simbol!';
              flag = false;
            }
          }
        }
      });
    });
    if (flag) {
      return { success: true, error };
    }
    return { success: false, error };
  },
  Color() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  },
  FormatDate(dates) {
    if (dates != undefined) {
      const date = new Date(dates);
      const dateTimeFormat = new Intl.DateTimeFormat('id', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      });
      const [{ value: day }, , { value: month }, , { value: year }] =
        dateTimeFormat.formatToParts(date);
      return `${day} ${month} ${year}`;
    }
  },
  formatNpwp(value) {
    if (typeof value === 'string') {
      if (value.length === 2) {
        return value + '.';
      }
      if (value.length === 6) {
        return value + '.';
      }
      if (value.length === 10) {
        return value + '.';
      }
      if (value.length === 12) {
        return value + '-';
      }
      if (value.length === 16) {
        return value + '.';
      }
      return value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/,
        '$1.$2.$3.$4-$5.$6'
      );
    }
  },
  UnformatNpwp(value) {
    const a = value.replaceAll('-', '');
    const b = a.replaceAll('.', '');
    const c = b.toString();
    return c;
  },
  FormatRp(val) {
    return 'Rp ' + this.FormatNumber(val);
  },
  UnFormatRp(x) {
    let result = null;
    if (x && typeof x === 'string') {
      const val = x;
      const parts = val.replace(/,/g, '');
      result = parts;
    }
    return result;
  },
  FormatNumber(x) {
    let result = null;
    if (x || x === 0) {
      x = x.toString();
      if (x.length > 1) {
        while (x.charAt(0) === '0') {
          x = x.slice(1);
        }
      }

      const parts = x.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      result = parts.join('.');
    }
    return result;
  },
  checkPermission(key) {
    const found = role.find((element) => element.module_prefix === key);
    return found != undefined;
  },
  currencyFormatter(param) {
    const formatterProps = {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    };
    const result = new Intl.NumberFormat('id-ID', formatterProps).format(param);
    return result;
  },
  gramFormatter(param) {
    return param + ' gram';
  },
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  isValidFileUploadedImage(file) {
    const validExtensions = ['png', 'jpeg', 'jpg'];
    const fileExtension = file.type.split('/')[1];
    return validExtensions.includes(fileExtension);
  },
  joinDescription(arr, data) {
    let val = [];
    arr.map((value, index) => {
      if (!value._destroy) {
        const desc = data['description' + value.key] || '';
        val.push(desc);
      }
    });
    const result = val.join('\n');
    return result;
  },
};

export default Func;
