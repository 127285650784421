import axios from 'axios';
import env from '../../config/env';
import { SetAuthTokenRequest, checkTokenExpired } from '../../config/interceptor';

const api = axios.create({
  baseURL: env.masterApi + env.apiPrefixV1
});
api.interceptors.request.use(SetAuthTokenRequest, null);
api.interceptors.response.use(null, checkTokenExpired);

export const indexNotificationApi = () => api.get('/notifications', { params: { per_page: 1000, page: 1 } });
export const readOneNotificationApi = (id) => api.get(`/notifications/${id}`);
export const deleteOneNotificationApi = (id) => api.delete(`/notifications/${id}`);
export const readAllNotificationApi = () => api.put('/notifications/read_all');
export const deleteAllNotificationApi = () => api.delete('/notifications/all');
