/* eslint-disable camelcase */
/* eslint-disable no-trailing-spaces */
import Loadable from 'react-loadable';
import { Loader as Loading } from '../../components/Loader';

// Dashboard

export const DashboardPage = Loadable({
  loader: () => import('./Dashboard'),
  loading: Loading,
});

// Data Nasabah (Master Data) -> HPS Elektronik

export const CustomerIndividual = Loadable({
  loader: () => import('./Customers/Individual'),
  loading: Loading,
});
export const HPSElektronik = Loadable({
  loader: () => import('./Customers/Elektronik'),
  loading: Loading,
});
export const SalesAgent = Loadable({
  loader: () => import('./Customers/SalesAgent'),
  loading: Loading,
})

// Manajemen Transaksi

export const TransactionIndividual = Loadable({
  loader: () => import('./Transactions/Individual'),
  loading: Loading,
});

export const DeviationApproval = Loadable({
  loader: () => import('./Transactions/Deviation'),
  loading: Loading,
});

export const OneObligor = Loadable({
  loader: () => import('./Transactions/OneObligor'),
  loading: Loading,
});

export const NonTransactional = Loadable({
  loader: () => import('./Transactions/NonTransactional'),
  loading: Loading,
});

export const HistoryProlongation = Loadable({
  loader: () => import('./Transactions/HistoryProlongation'),
  loading: Loading,
});

// Finansial Manajemen

export const Request = Loadable({
  loader: () => import('./FinancialManagement/Request'),
  loading: Loading,
});

export const MiscellaneousExpense = Loadable({
  loader: () => import('./FinancialManagement/MiscellaneousExpense'),
  loading: Loading,
});

// Jurnal

export const Journal_All = Loadable({
  loader: () => import('./Journal/All'),
  loading: Loading,
});

export const Accrual = Loadable({
  loader: () => import('./Journal/Accrual'),
  loading: Loading,
});

export const EntryJournalForm = Loadable({
  loader: () => import('./Journal/components/EntryJournalForm'),
  loading: Loading,
});

// Laporan Transaksi

export const TranscationReport = Loadable({
  loader: () => import('./Report/All'),
  loading: Loading,
});

// Manajemen Kas

export const Cashflow = Loadable({
  loader: () => import('./Cashflow/Cashflow'),
  loading: Loading,
});

export const CashDisbursements = Loadable({
  loader: () => import('./Cashflow/CashDisbursements'),
  loading: Loading,
});

export const CashJournal = Loadable({
  loader: () => import('./Cashflow/CashJournal'),
  loading: Loading,
});

export const PettyCash = Loadable({
  loader: () => import('./Cashflow/PettyCash'),
  loading: Loading,
});

export const CombinedCash = Loadable({
  loader: () => import('./Cashflow/CombinedCash'),
  loading: Loading,
});

// Modal Kerja

export const MokerHistory = Loadable({
  loader: () => import('./Workcap/Moker'),
  loading: Loading,
});

export const WorkcapApproval = Loadable({
  loader: () => import('./Workcap/Approval'),
  loading: Loading,
});

export const WorkcapMutation = Loadable({
  loader: () => import('./Workcap/WorkcapMutation'),
  loading: Loading,
});

// Setting

export const Profile = Loadable({
  loader: () => import('./Setting/Profile'),
  loading: Loading,
});

export const ChangePassword = Loadable({
  loader: () => import('./Setting/ChangePassword'),
  loading: Loading,
});

// etc

export const accessDenied = Loadable({
  loader: () => import('../../routes/accessDenied'),
  loading: Loading,
});

export const AllNotification = Loadable({
  loader: () => import('./Notification'),
  loading: Loading,
});

export const TransactionStock = Loadable({
  loader: () => import('./Transactions/Stock'),
  loading: Loading,
});
