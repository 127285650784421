/* eslint-disable semi */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Func from '../../functions/index';
const PrivateRoute = ({ component: Component, ...props }) => {
  const isLoggedIn = !!localStorage.getItem('token');
  const haveAccess = Func.checkPermission(props.path.slice(5).replace(/\//g, '#'));
  return (
    <Fragment>
      <Route
        {...props}
        render={
          (props) => (isLoggedIn ? (haveAccess ? <Component {...props} /> : <Redirect to="/app/page/access-denied" />) : (<Redirect to="/login" />))
        }
      />
    </Fragment>
  );
};

PrivateRoute.propTypes = ({
  component: PropTypes.object.isRequired,
});

export default PrivateRoute;
