/* eslint-disable semi */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Route } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  lists: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  typography: {
    fontSize: 12
  }
}));

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

export default function RouterBreadcrumbs(props) {
  const { breadcrumbNameMap } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Route>
        {({ location }) => {
          const pathnames = location.pathname.split('/').filter((x) => x);
          const cleanPathnames = _.pull(pathnames, 'app');
          return (
            <Breadcrumbs aria-label="breadcrumb">
              <LinkRouter color="inherit" to="/app/home/dashboard" className={classes.typography}>
                Gcda Siscab
              </LinkRouter>
              {cleanPathnames.map((value, index) => {
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                return (
                  <Typography color="textPrimary" key={to} className={classes.typography}>
                    {breadcrumbNameMap[to]}
                  </Typography>
                )
              })}
            </Breadcrumbs>
          );
        }}
      </Route>
    </Paper>
  );
}
