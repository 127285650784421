/* eslint-disable camelcase */
import { configureStore, combineReducers, createSlice } from '@reduxjs/toolkit';

import notification from './notification/reducer';

const pageInfoSlice = createSlice({
  name: 'pageInfo',
  initialState: {
    title: '',
  },
  reducers: {
    setPageTitleAction: (state, { payload }) => ({ ...state, title: payload })
  },
});

export const {
  setPageTitleAction
} = pageInfoSlice.actions;

export const setPageTitle = (title) => async (dispatch) => dispatch(setPageTitleAction(title));

const userAccountSlice = createSlice({
  name: 'userAccount',
  initialState: {
    picture: '',
  },
  reducers: {
    setPictureAction: (state, { payload }) => ({ ...state, picture: payload })
  },
});

export const {
  setPictureAction,
} = userAccountSlice.actions;

export const setPicture = (url) => async (dispatch) => dispatch(setPictureAction(url));

const rootReducer = combineReducers({
  notification,
  pageInfo: pageInfoSlice.reducer,
  userAccount: userAccountSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
});

export default store;
