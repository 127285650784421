import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import SecureRoutes from './SecureRoutes';
import NotFound from './NotFound';
import Login from '../container/Pages/Auth/Login';
import Forget from '../container/Pages/Auth/Forget';
import ResetPassword from '../container/Pages/Auth/ResetPassword';

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route path="/lupa-kata-sandi" component={Forget} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/app" render={(props) => <SecureRoutes {...props} />} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
}
export default App;
