/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useCallback, useMemo } from 'react';
import {
  indexNotification,
  deleteOneNotification,
  readOneNotification,
  readAllNotification,
  deleteAllNotification,
  addOneNotification,
} from './reducer';

function paginator(items, current_page = 1, per_page = 10) {
  const offset = (current_page - 1) * per_page;

  const paginatedItems = items.slice(offset).slice(0, per_page);
  const total_page = Math.ceil(items.length / per_page);

  return {
    current_page,
    data: paginatedItems,
    next_page: (total_page > current_page) ? current_page + 1 : null,
    prev_page: current_page - 1 ? current_page - 1 : null,
    total_data: items.length,
    total_page,
  };
}

export default function useNotification() {
  const hydrated = useSelector(({ notification }) => notification?.hydrated || false);
  const dispatch = useDispatch();
  const list = useSelector(({ notification }) => notification.list || []);
  const listUnread = useMemo(() => list.filter((item) => !item.is_read), [list]);
  const listRead = useMemo(() => list.filter((item) => item.is_read), [list]);
  const unreadCount = useSelector(({ notification }) => notification.unreadCount || 0);
  const totalCount = useSelector(({ notification }) => notification.totalCount || 0);
  const hydrate = useCallback(async () => dispatch(indexNotification()), [dispatch]);
  const deleteOne = useCallback(async (id) => dispatch(deleteOneNotification(id)), [dispatch]);
  const readOne = useCallback(async (id) => dispatch(readOneNotification(id)), [dispatch]);
  const readAll = useCallback(async () => dispatch(readAllNotification()), [dispatch]);
  const deleteAll = useCallback(async () => dispatch(deleteAllNotification()), [dispatch]);
  const addOne = useCallback(async (item) => dispatch(addOneNotification(item)), [dispatch]);
  const paginatedList = useCallback((current_page, per_page) => paginator(list, current_page, per_page), [list]);

  useEffect(() => {
    if (!hydrated) {
      hydrate();
    }
  }, [hydrated, hydrate]);

  return {
    hydrated,
    list,
    listUnread,
    listRead,
    unreadCount,
    totalCount,
    deleteOne,
    readOne,
    readAll,
    deleteAll,
    paginatedList,
    addOne,
  };
}
