/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import { createSlice } from '@reduxjs/toolkit';
import {
  indexNotificationApi,
  readAllNotificationApi,
  readOneNotificationApi,
  deleteAllNotificationApi,
  deleteOneNotificationApi,
} from './api';

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    hydrated: false,
    list: [],
    unreadCount: 0,
    totalCount: 0,
  },
  reducers: {
    indexAction: (state, { payload }) => ({ ...state, hydrated: true, ...payload }),
    addOneAction: ({ unreadCount, totalCount, list, ...state }, { payload }) => (
      {
        ...state,
        unreadCount: unreadCount + 1,
        totalCount: totalCount + 1,
        list: list.concat([payload])
      }
    ),
    readAllAction: ({ list, ...state }) => ({
      ...state,
      unreadCount: 0,
      list: list.map((prev) => ({ ...prev, is_read: true }))
    }),
    readOneAction: ({ list, unreadCount, ...state }, { payload }) => ({
      ...state,
      list: list.map(({ id, is_read, ...item }) => ({ ...item, id, is_read: payload === id ? true : is_read })),
      unreadCount: unreadCount > 1 ? unreadCount - 1 : 0,
    }),
    deleteAllAction: (state) => ({
      ...state,
      list: [],
      unreadCount: 0,
      totalCount: 0
    }),
    deleteOneAction: ({ list, unreadCount, totalCount, ...state }, { payload }) => {
      const deletedItem = list.find(({ id }) => id === payload);
      let nextTotalCount = totalCount - 1;
      nextTotalCount = nextTotalCount < 0 ? 0 : nextTotalCount;
      let nextUnreadCount = !deletedItem.is_read ? unreadCount - 1 : unreadCount;
      nextUnreadCount = unreadCount < 0 ? 0 : nextUnreadCount;
      const nextList = list.filter((item) => item.id !== payload);
      return {
        ...state,
        list: nextList,
        unreadCount: nextUnreadCount,
        totalCount: nextTotalCount
      };
    },
  }
});

export const {
  indexAction,
  addOneAction,
  readAllAction,
  readOneAction,
  deleteAllAction,
  deleteOneAction,
} = notificationSlice.actions;

export const indexNotification = () => async (dispatch) => {
  try {
    const { status, data: { data: list = [], total_data: totalCount = 0 } } = await indexNotificationApi();
    if (status === 200) {
      dispatch(indexAction({ list, unreadCount: list.filter((item) => !item.is_read).length, totalCount }));
    } else {
      dispatch(indexAction({ list, unreadCount: 0, totalCount }));
    }
  } catch (e) {
    dispatch(indexAction({ list: [], unreadCount: 0, totalCount: 0 }));
  }
};

export const addOneNotification = (item) => async (dispatch) => dispatch(addOneAction(item));

export const deleteOneNotification = (id) => async (dispatch) => {
  try {
    const { status } = await deleteOneNotificationApi(id);
    if (status === 200) {
      dispatch(deleteOneAction(id));
    }
  } catch (e) {
    console.log(e);
  }
};

export const readOneNotification = (id) => async (dispatch) => {
  try {
    const { status } = await readOneNotificationApi(id);
    if (status === 200) {
      dispatch(readOneAction(id));
    }
  } catch (e) {
    console.log(e);
  }
};

export const deleteAllNotification = () => async (dispatch) => {
  try {
    const { status } = await deleteAllNotificationApi();
    if (status === 200) {
      dispatch(deleteAllAction());
    }
  } catch (e) {
    console.log(e);
  }
};

export const readAllNotification = () => async (dispatch) => {
  try {
    const { status } = await readAllNotificationApi();
    if (status === 200) {
      dispatch(readAllAction());
    }
  } catch (e) {
    console.log(e);
  }
};

export default notificationSlice.reducer;
